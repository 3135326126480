// src/pages/Home.js
import React, { useState } from 'react';
import './Home.css';

const Home = () => {
  // State to manage the current subscription
  const [subscriptionFullName] = useState('');

  // Function to get order limit text based on the plan
  const getOrderLimitText = (plan) => {
    switch (plan) {
      case 'Basic':
        return 'Up to 250 Orders Per Month';
      case 'Gold':
        return 'Up to 2,000 Orders Per Month';
      case 'Platinum':
        return 'Up to 5,000 Orders Per Month';
      case 'Diamond':
        return 'Unlimited Orders Per Month';
      default:
        return '';
    }
  };

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero__content">
          <h1>Make Your Sweepstakes a Success</h1>

          {/* New "See our app on Shopify" Button */}
          <a
            href="https://apps.shopify.com/clean-sweeps"
            target="_blank"
            rel="noopener noreferrer"
            className="hero__shopify-button"
          >
            See Our App On Shopify
          </a>

          <p>Choose the plan that best fits your needs and start growing today.</p>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="pricing-section">
        <h2>Our Pricing Plans</h2>
        <div className="pricing-container">
          {/* Basic Plan */}
          <a
            href="https://apps.shopify.com/clean-sweeps"
            target="_blank"
            rel="noopener noreferrer"
            className={`pricing-card ${subscriptionFullName.includes('Basic') ? 'current-plan' : ''}`}
          >
            <h3>Basic</h3>
            <p className="price">
              $30<span>/month</span>
            </p>
            <ul>
              <li>Run One Active Sweepstakes</li>
              <li>Export All Contestants To A .CSV</li>
              <li>Show Entries Earned For Each Product</li>
              <li>Display Entries In Customer Accounts</li>
              <li>{getOrderLimitText('Basic')}</li>
            </ul>
          </a>

          {/* Gold Plan */}
          <a
            href="https://apps.shopify.com/clean-sweeps"
            target="_blank"
            rel="noopener noreferrer"
            className={`pricing-card ${subscriptionFullName.includes('Gold') ? 'current-plan' : ''}`}
          >
            <h3>Gold</h3>
            <p className="price">
              $99<span>/month</span>
            </p>
            <ul>
              <li>Run Multiple Sweepstakes Simultaneously</li>
              <li>Guest Entry Lookup</li>
              <li>Checkout Extensions</li>
              <li>Bonus Spending Entries</li>
              <li>{getOrderLimitText('Gold')}</li>
            </ul>
          </a>

          {/* Platinum Plan */}
          <a
            href="https://apps.shopify.com/clean-sweeps"
            target="_blank"
            rel="noopener noreferrer"
            className={`pricing-card ${subscriptionFullName.includes('Platinum') ? 'current-plan' : ''}`}
          >
            <h3>Platinum</h3>
            <p className="price">
              $199<span>/month</span>
            </p>
            <ul>
              <li>Turn Customers Into Ambassadors With Referrals</li>
              <li>Winner Selection</li>
              <li>Full Sweepstakes Customization</li>
              <li>Store And Checkout Customizations</li>
              <li>{getOrderLimitText('Platinum')}</li>
            </ul>
          </a>

          {/* Diamond Plan */}
          <a
            href="https://apps.shopify.com/clean-sweeps"
            target="_blank"
            rel="noopener noreferrer"
            className={`pricing-card ${subscriptionFullName.includes('Diamond') ? 'current-plan' : ''}`}
          >
            <h3>Diamond</h3>
            <p className="price">
              $539<span>/month</span>
            </p>
            <ul>
              <li>Unlimited Sweepstakes Campaigns</li>
              <li>All Features From All Tiers</li>
              <li>Exclusive Widget Customization</li>
              <li>Premium Customer Support</li>
              <li>{getOrderLimitText('Diamond')}</li>
            </ul>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Home;
